export const items = 
[
    { title: 'Договоры', id: 1, name: "contracts"}, 
    { title: 'Дети', id: 2, name: "children"}, 
    { title: 'Группы', id: 3, name: "groups"},
    //{ title: 'Сотрудники', id: 4, name: "spec"},
    { title: 'Взаимодействие', id: 5, name: "collaboration"},
    { title: 'Письма', id: 6, name: "letters"},
   //{ title: 'Задачи', id: 7, name: "worksplan"},
    { title: 'Контакты', id: 8, name: "contacts"},
    { title: 'Объявления и сообщения', id: 9, name: "messages"},
    { title: 'Держатели', id: 10, name: "trustees"},
   // { title: 'Обратная связь', id: 11, name: "feedback"},
    { title: 'Питание', id: 12, name: "food"},
    { title: 'База знаний', id: 13, name: "baseKnowledge"},
   // { title: 'Конструктор занятий', id: 14, name: "lessonDesigner"},
    { title: 'Медиатека', id: 15, name: "mediaLibrary"},
    { title: 'Диагностика', id: 16, name: "diagnostics"},
    { title: 'Мониторинг', id: 17, name: "monitoring"},
    { title: 'Расписание', id: 18, name: "calendar"},
   // { title: 'Планирование', id: 19, name: "planning"},
    { title: 'Профиль 1', id: 20, name: "profileOne"},
    { title: 'Профиль 2', id: 21, name: "profileTwo"},
    { title: 'Профиль 3', id: 22, name: "profileThree"},
    { title: 'Профиль 4', id: 23, name: "profileFour"},
];

export default items;